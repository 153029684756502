import React, { useEffect, useState } from "react";
import { Shell, Card, Text, Grid, Row, SearchInput } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/VideoOnDemand";
import { connect } from "react-redux";
import { displayFilter } from "../utils/utils";
import ComingSoon from "./ComingSoon";
import Vod_coming_soon from "../assets/images/vod_coming_soon.gif";

const VideoOnDemand = (props) => {
  let { videoOnDemandList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [placeholder, setPlaceholder] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId) => {
    props.history.push(`/voddetail/${eventId}`);
  };

  useEffect(() => {
    props.fetchVideoOnDemand();
    getPlaceholder()
  }, []);

  const getPlaceholder = async () => {
    const val = await actions.fetchVODPlaceholder();
    setPlaceholder(val)
  }

  const onFilter = (e) => {
    setFilterValue(e.target.value)
  }

  if (videoOnDemandList.length == 0 && placeholder) {
    let image
   if (placeholder.length == 0) {
     image = Vod_coming_soon
    } else {
      let imageArray = placeholder[0].placeholderImage
      ? placeholder[0].placeholderImage.split(","): "";
      image = imageArray ? imageArray[1] : "";
    }
    return <ComingSoon image={image} />
  }

  return (
    <Shell.Body className="css-work-shop">
      <Grid>
        <div className="card-filter">
          <SearchInput placeholder='Filter' onChange={onFilter} />
        </div>
        <Row>
          {videoOnDemandList
            .filter(({title}) => {
              let regex = /<.+>(?<title>.+)<.+>/;
              let output = title.match(regex);
              return output[1].toUpperCase().includes(filterValue.toUpperCase()) 
            })
            .map((item) => {
              item.heading = item.title.replace(/(<([^>]+)>)/gi, "");
              return item;
            })
            .sort((a, b) => (a.heading > b.heading ? 1 : -1))
            .map((slide, index) => {
              let imageArray = slide.thumbNail
                ? slide.thumbNail.split(",")
                : "";
              let image = imageArray ? imageArray[1] : "";
              return (
                <div className="contestant-tile" key={index}>
                  <Card
                    key={index}
                    className={"video-slides"}
                    onClick={() => handleClick(slide?.id)}
                    noPadding={false}
                    noBoxShadow={false}
                    noBorder={true}
                    noBorderRadius={true}
                  >
                    <img src={image} alt="" />
                    <div className="meta-details">
                      <div
                        className={"title-tiles"}
                        dangerouslySetInnerHTML={{ __html: slide?.title }}
                      />
                      <div>
                        <Text as="p" className="style-p">
                          {"Duration: " + slide?.duration + " mins"}{" "}
                        </Text>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
        </Row>
      </Grid>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  videoOnDemandList: displayFilter(state.VideoOnDemand.videoOnDemandList),
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideoOnDemand: () => dispatch(actions.fetchVideoOnDemand()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoOnDemand);
